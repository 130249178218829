// WETH9:
// 0xdAD51A97B5582396E9cD9D910b45d7dD8E26c9c0

// UniswapV2Factory:
// 0x835E0f80Deb0bBEfF24E97943762D053438c4ffd

// UniswapV2Router02:
// 0x9471F3d12E68ce8A9186DA2281E3760129Fa8D4B

// INIT_CODE_HASH:
// e5e068031409b9d730ba1fc59b0ce3b6dd8f7d2398549b6f019150cd22bf2b9a

export default {
  MAINNET: {
    WETH9: '0x517E9e5d46C1EA8aB6f78677d6114Ef47F71f6c4',
    UniswapV2Factory: '0xFc09C4A466A4FBa6bE80009cee63E24C2F168371',
    UniswapV2Router02: '0x1FF9598aBCBbC2F3A9B15261403459215b352e2b',
    INIT_CODE_HASH: '0x77d35d84db83d346d845a23b35a87b92e27c84e765e40c1ea3baaf83c1c6ad3d',
    EscscanLink: 'https://escscan.elaphant.app'
  },
  DEV: {
    WETH9: '0xe6E741a2342C5DfF3Bb27FDe82cf64Ab7F29B435',
    UniswapV2Factory: '0xa47669710B907cE433BAC0356cC18C47B59AD7BB',
    UniswapV2Router02: '0x649aebca1af302f78533e7059a1b387fb8dacdd8',
    INIT_CODE_HASH: '0x23b00f9921fa9d868a981c0e72a04dfaa95b8a347f676a0f26292230793baeb0',
    EscscanLink: 'https://escscan.elaphant.app'
  },
  TESTNET: {
    WETH9: '0x517e9e5d46c1ea8ab6f78677d6114ef47f71f6c4',
    UniswapV2Factory: '0x601a13b6f78979fecbd705c203f0a47b380a39e3',
    UniswapV2Router02: '0x112e47Ad904D58d1f0F015fFC1521EA11e02F1f9',
    INIT_CODE_HASH: '0x77d35d84db83d346d845a23b35a87b92e27c84e765e40c1ea3baaf83c1c6ad3d',
    EscscanLink: 'https://escscan.elaphant.app'
  },
  HECO_MAINNET: {
    WETH9: '0x45b2165cc04673bb711605c43a7d1279ff838477',
    UniswapV2Factory: '0xEcC694Bf665E1ba7fb1546ae40b8FaD6f3462B28',
    UniswapV2Router02: '0x64967f9889a14a710c4f6d21f3a2155035310048',
    INIT_CODE_HASH: '0x77d35d84db83d346d845a23b35a87b92e27c84e765e40c1ea3baaf83c1c6ad3d',
    EscscanLink: 'https://scan.hecochain.com'
  },
  HECO_TESTNET: {
    WETH9: '0x45b2165cc04673bb711605c43a7d1279ff838477',
    UniswapV2Factory: '0x52354Bd079373fB96fCA3CFE3cD5a0319bB3c169',
    UniswapV2Router02: '0xf545102a8daa89a79ba8586e2ee278b82c48ae86',
    INIT_CODE_HASH: '0x77d35d84db83d346d845a23b35a87b92e27c84e765e40c1ea3baaf83c1c6ad3d',
    EscscanLink: 'https://scan-testnet.hecochain.com'
  }
}
