import { ChainId } from '@uniswap/sdk'
import MULTICALL_ABI from './abi.json'

const MULTICALL_NETWORKS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0x742637171716F52EF90873b1Bba06477C7ee7e79',
  [ChainId.ROPSTEN]: '0x53C43764255c17BD724F74c4eF150724AC50a3ed',
  [ChainId.KOVAN]: '0x2cc8688C5f75E365aaEEb4ea8D6a480405A48D2A',
  [ChainId.RINKEBY]: '0x42Ad527de7d4e9d9d011aC45B31D8551f8Fe9821',
  [ChainId.GÖRLI]: '0x77dCa2C955b15e9dE4dbBCf1246B4B85b651e50e',
  [ChainId.DEV]: '0x70D4706EBEe304D802b8B379D5be945E8270AE53',
  [ChainId.TESTNET]: '0x2bddc56abc88a63d4f1b26d6f327a5cfdae7192b',
  [ChainId.HECO_TESTNET]: '0x1EAF75a5FA6f836568cf221262A79bb7c4CA3A66',
  [ChainId.HECO_MAINNET]: '0xf545102a8daa89a79ba8586e2ee278b82c48ae86'
}

export { MULTICALL_ABI, MULTICALL_NETWORKS }
