import React from 'react'
import Modal from '../Modal'
import Logo from '../../assets/svg/logo2.svg'
import Wordmark from '../../assets/svg/wordmark2.svg'

interface AboutModalProps {
  isOpen: boolean
  onDismiss: () => void
}

export default function AboutModal({ isOpen, onDismiss }: AboutModalProps) {
  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxHeight={90}>
      <div style={{ position: 'relative', margin: '35px', height: '140px' }}>
        <img src={Logo} style={{ position: 'absolute', left: '-13px', top: '10px', width: '102px' }} alt="Logo" />
        <div style={{ position: 'absolute', left: '94px' }}>
          <img src={Wordmark} width="230" alt="Wordmark" />
          <div style={{ width: '274px', marginTop: '10px' }}>
            TokSwap is decentralized protocol for automated liquidity provision on Elastos.It was forked from Uniswap.
          </div>
          <div style={{ fontWeight: 'bold', marginTop: '10px' }}>@2020 ElaBank</div>
        </div>
      </div>
    </Modal>
  )
}
